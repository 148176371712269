.fluidMedia {
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.fluidMedia iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overflow-div {
	max-width: 100%;
	position: relative;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}

.ad-block.ad-billboard .top-adv {
	position: relative;
	top: 3px;
	left: 0%;
	margin: auto;
}

.side-menu {
	-webkit-overflow-scrolling: touch;
}

.footer-nav {
	position: absolute;
}

.navigation {
	height: auto;
}

.image-wrap {
    display: inline-block;
    position: relative;
    max-width: initial;
		width: auto;
    max-width:100%;
}

/*.image-wrap img {*/
    /*max-width: 100%;*/
    /*height: auto;*/
		/*width: 100%;*/
/*}*/

.image-float-left {
    float: left;
    margin-right: 10px;
}

.image-float-right {
    float: right;
    margin-left: 10px;
}

.image-no-float {
    float :none;
}

.img-full {
    position: relative;
}

.img-full-with-bg {
	overflow: hidden;
}

.full-img-bg {
  max-width: 100%;
  /*height: 358px;*/
  height: auto;
  max-height: 358px;
  background-size: cover;
  position: relative;
  background-position: 50%;
  /*margin: -10px;*/

  -webkit-filter: blur(40px);
  -moz-filter: blur(40px);
  -o-filter: blur(40px);
  -ms-filter: blur(40px);
  filter: blur(40px);
}
.slide-show-slide-item .full-img-bg {
    max-height: 398px;
}

.menu-active {
	overflow: hidden;
}

.without-margin {
	margin: 0px !important;
}

.without-padding {
	padding: 0px !important;
}

.social-header ul li.menu-icon a:hover {
	background: #363636 none repeat scroll 0% 0%;
	color: #FFF;
	border-color: #EDEDED;
}

.social-header ul li.twitter a:focus,
.social-header ul li.facebook a:focus {
	background: #FFF none repeat scroll 0% 0%;
	color: #5d5d5d;
}

.full-img {
	text-align: center;
	top: 0px;
	left: 0px;
	width: 100%;
}

.head-filter.top-position {
  margin-bottom: 25px;
  overflow: hidden;
}
.head-filter.top-position .select-wrapper {
  border: none;
}

.media a.pull-left {
	max-width: 120px;
}

.titlebar {
  color: #fff !important;
  font-family: "tradegothicno2bold" !important;
  font-size: 14px !important;
  font-style: normal !important;
}

/*.recommended-content .media {*/
    /*border-bottom: none;*/
    /*display: block;*/
    /*text-align: left;*/
/*}*/

/*.recommended-content .media img {*/
    /*width: 100%;*/
    /*/!*max-width: 212px;*!/*/
/*}*/

/*.recommended-content .media-body {*/
    /*padding: 20px 0 0;*/
    /*display: block;*/
    /*width: auto;*/
    /*max-width: 150px;*/
/*}*/

/*.recommended-content .media-body h4 {*/
    /*padding: 0 0;*/
    /*font-size: 1.13rem;*/
    /*line-height: 1.5rem;*/
/*}*/

.lazy-fix-mt {
    margin-top:20px;
}

.page-nav ul li.prev a {
    border-right: none;
}

.page-nav ul li.current {
    border-left: 1px solid #fff;
}

#map-canvas {
    height: 450px;
}

.modal-header {
    color: white;
}

#input-link{
	height: 60px;
	letter-spacing: 1px;
}

.share-btn-fix {
	height: 60px;
	letter-spacing: 3px;
	padding: 9px 45px 9px;
}

.br0 {
	border-radius: 0px !important;
}

.soc-icon {
    display:table-cell;
    vertical-align:middle;
    height: 80px;
    width: 80px;
    background: white;
    text-align:center
}

.soc-icons {
    padding-top: 40px;
    padding-bottom: 40px;
}

.soc-text {
    color: white;
    padding-top: 10px;
}

.not-active {
    visibility: hidden;
}

.soc-link {
    text-align: center;
    display: inline-block;
}

#soc-header {
    border-bottom: none;
		padding-right: 0px;
		padding-left: 0px;
}

.modal-title.share-title {
	margin-bottom: 10px;
}

.soc-hr {
    border-top: 1px solid black;
    border-bottom: 1px solid #353535;
}

.categories-title {
    color: #F48A31;
}


.drug-image {
    vertical-align: 5px;
}

.drug-header {
    display: inline;
}

.form-control.mobile-selectnojs {
    -moz-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #c3c3c3;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
    background: url("../images/svg/select.svg") no-repeat top -1px right -1px, linear-gradient(#ffffff 20%, #e7e7e7 100%);
    height: 28px;
    margin: 0 0 0 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #5d5d5d;
    padding: 3px 42px 3px 12px; }
.form-control.mobile-selectnojs:focus {
    border: 1px solid #c3c3c3; }

/*.ad-block.ad-middle {*/
    /*padding: 25px 0px 25px; }*/

/*.ad-block.ad-middle.visible-lg {*/
    /*margin-right: 0px;*/
/*}*/


.ad-block.ad-middle.text-center.visible-xs {
    margin-bottom: 20px;
    /*margin-top: -20px;*/
}

.ad-block.ad-middle.text-center.visible-lg {
    /*margin-top: -10px;*/
    padding-bottom: 25px;
}

.adv-widget {
	display: block;
}

.quick-select-filter {
    padding: 0px 0px 50px;
}

.clasification {
    vertical-align: middle;
}

.tag-widget-fix-pb {
    padding-bottom: 10px!important;
}

.slide-img {
	height: 460px;
	width: 820px;
	position: relative;
}

.slide-img .img-responsive {
	max-height: 100%;
	max-width: 100%;
	width: auto;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.carousel-caption {
	top: 460px;
}

.modal-customize{
	max-width: 400px;
}

#close-customize{
	position: absolute;
	top: -25px;
	left: 95%;
	color: #FFFFFF;
}

.text-posts .half-col:first-child .text-title {
	border-right: none;
}

.text-posts .half-col:first-child ul {
	border-right: none;
}

.hr-with-negative-margin-top-30px {
	margin-top: -30px;
}

.fb-like {
	margin-right: 15px;
	display: inline-block;
	/*top: 0px !important;*/
}

.share-actions li {
	padding: 0px 0px 0px 13px !important;
}

.head-page .share-actions {
	padding: 17px 0px 0px;
}

.meta.date.blog-meta span {
	clear: both;
	display: inline;
}


.video iframe {
	width: 100%;
	/*height: 100%;*/
	-webkit-overflow-scrolling: touch;
	overflow: auto;
}

.div-soc-icon {
	height: 80px;
	width: 80px;
}

.twitter-icon {
	background-image: url('../images/soc-icons/twitter.png');
	background-repeat: no-repeat;
	background-position: center;
}

.twitter-icon:hover {
	background-image: url('../images/soc-icons/twitter_white.png');
	background-repeat: no-repeat;
	background-position: center;
}

.facebook-icon {
	background-image: url('../images/soc-icons/facebook.png');
	background-repeat: no-repeat;
	background-position: center;
}

.facebook-icon:hover {
	background-image: url('../images/soc-icons/facebook_white.png');
	background-repeat: no-repeat;
	background-position: center;
}

.googleplus-icon {
	background-image: url('../images/soc-icons/googleplus.png');
	background-repeat: no-repeat;
	background-position: center;
}

.googleplus-icon:hover {
	background-image: url('../images/soc-icons/google_white.png');
	background-repeat: no-repeat;
	background-position: center;
}

.pinterest-icon {
	background-image: url('../images/soc-icons/pinterest.png');
	background-repeat: no-repeat;
	background-position: center;
}

.pinterest-icon:hover {
	background-image: url('../images/soc-icons/pinterest_white.png');
	background-repeat: no-repeat;
	background-position: center;
}

.tumblr-icon {
	background-image: url('../images/soc-icons/tumblr.png');
	background-repeat: no-repeat;
	background-position: center;
}

.tumblr-icon:hover {
	background-image: url('../images/soc-icons/tumblr_white.png');
	background-repeat: no-repeat;
	background-position: center;
}

.mail-icon {
	background-image: url('../images/soc-icons/mail.png');
	background-repeat: no-repeat;
	background-position: center;
}

.mail-icon:hover {
	background-image: url('../images/soc-icons/mail_white.png');
	background-repeat: no-repeat;
	background-position: center;
}

@media
(-webkit-min-device-pixel-ratio: 2) {
	.twitter-icon {
		background-image: url('../images/soc-icons/twitter_icon_@2x.png');
		background-size: 38px;
	}

	.twitter-icon:hover {
		background-image: url('../images/soc-icons/twitter_icon_white_@2x.png');
		background-size: 38px;
	}

	.facebook-icon {
		background-image: url('../images/soc-icons/facebook_icon_@2x.png');
		background-size: 18px;
	}

	.facebook-icon:hover {
		background-image: url('../images/soc-icons/facebook_icon_white_@2x.png');
		background-size: 18px;
	}

	.googleplus-icon {
		background-image: url('../images/soc-icons/google_icon_@2x.png');
		background-size: 38px;
	}

	.googleplus-icon:hover {
		background-image: url('../images/soc-icons/google_icon_white_@2x.png');
		background-size: 38px;
	}

	.pinterest-icon {
		background-image: url('../images/soc-icons/pinterest_icon_@2x.png');
		background-size: 38px;
	}

	.pinterest-icon:hover {
		background-image: url('../images/soc-icons/pinterest_icon_white_@2x.png');
		background-size: 38px;
	}

	.tumblr-icon {
		background-image: url('../images/soc-icons/tumblr_icon_@2x.png');
		background-size: 22px;
	}

	.tumblr-icon:hover {
		background-image: url('../images/soc-icons/tumblr_icon_white_@2x.png');
		background-size: 22px;
	}

	.mail-icon {
		background-image: url('../images/soc-icons/mail_icon_@2x.png');
		background-size: 38px;
	}

	.mail-icon:hover {
		background-image: url('../images/soc-icons/mail_icon_white_@2x.png');
		background-size: 38px;
	}
}

.nav-menu-closed {
	background: #FFF none repeat scroll 0% 0% !important;
	color: #363636 !important;
	border-color: #EDEDED !important;
}

.page-nav ul li a span.name-magazine {
	font-size: 1.1rem;
	letter-spacing: -0.3pt;
	color: #363636;
	white-space: nowrap;
}

.page-nav ul li a:hover > .name-magazine, .page-nav ul li a:hover > .title {
	color: #FFF;
	opacity: 1;
}

.magazine-thumbnail {
	min-width: 0px !important;
}


.modal-width {
    width: 400px !important;
}

.item-detail .meta.event-meta p span {
	letter-spacing: 1px;
}

.hook-for-content {
	padding-bottom: 15px;
	margin-top: -5px;
}

.social-author-detail {
	margin-bottom: 25px;
}

.full-height {
	height: 100%;
}

.ios {
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	overflow-x: scroll;
	max-height: 180px;
}
.frame.scroll-wrapper-ios.ios {
  max-height: none;
}
.scroll-wrapper-ios.ios {
  max-height: none !important;
}

.ios-widget {
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	overflow-x: scroll;
}

.scroll-wrapper iframe {
	height: 160px;
	width: 100%;
}

.without-border-bottom {
	border: none !important;
}

.recommended-title {
	margin-bottom: 0px;
	margin-top: 25px;
}

.recommended-item {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 10px;
}

.modal-header-newsletter, .modal-header-personal {
	border-bottom: none;
}

.modal-header-newsletter .section-title, .modal-header-personal .section-title{
	margin-bottom: 0px;
}

.modal-header-newsletter .section-title{
	margin-top: 23px;
}

.modal-body-newsletter, .modal-body-personal {
	padding-top: 0px;
}

.modal-body-personal {
	padding-bottom: 40px;
}

.special-newsletter-group {
	margin-bottom: 0px;
	margin-top: -15px;
}

.submit-newsletter-group {
	margin-bottom: 25px;
	margin-top: 20px;
}

.ad-block.ad-middle.visible-lg {
	margin-right: 0px;
}

.no-margin-right {
	margin-right: 0px !important;
}

.form-control.custom-selectnojs {
	width: 120px;
	margin: 0 0 0 25px;
}

.related-videos .item .slide-title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.card-small .card .author-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.video-description {
	height: 66px;
	color: #828282;
}

.related-videos, .video-detail > .related-articles {
	margin-left: -12px;
	margin-right: 12px;
}

.scroll-wrapper-widget {
	margin-bottom: 30px;
}

.clip-blogger-description {
	height: 65px;
	/*text-overflow: ellipsis;*/
	/*overflow: hidden;*/
}

.related-articles .media-body.media-body-recommended {
	max-width: 100%;
	border-bottom: 1px solid #EDEDED;
}

.btn-load-more {
	margin: auto;
}

.div-load-more {
	margin-top: -10px;
}

.after-div-load-more {
	margin-top: 10px;
}

.modal-adv {
	width: 300px;
}

.modal-body.modal-body-adv {
	padding: 0px;
}

.btn.btn-link.btn-modal-adv {
	color: #ffffff;
	text-decoration: none;
	text-transform: none;
	font-size: 16px;
	padding: 0px;
}

.modal-footer.modal-adv-footer {
	padding: 0px;
	border: none;
}

.modal-header.modal-adv-header {
	border: none;
	padding: 0px 0px 35px 0px;
}

.content-sub-block {
	height: 72px;
}

.img-responsive.img-in-body {
	margin-top: 0px;
}

#magazines-year {
	margin: 0 0 0 25px;
}

.special-personal-group {
	margin-top: 25px;
}

.wrapper-featured-img {
	min-height: 370px;
}

.sep-link-blogger {
	padding-right: 0px !important;
}

.in-side-menu {
	overflow-y: auto;
	overflow-x: visible;
	height: 100%;
	max-height: 100%;
	padding-bottom: 0px;
}

/*.navigation-container .side-menu {*/
	/*max-width: 370px;*/
/*}*/

ul.tags.tags-widget {
	margin-bottom: 10px;
	padding-bottom: 20px;
}

.main-posts .main-sub .post:first-child {
	padding: 0px 0px 25px;
}

.main-posts .main-sub .post.post-block-second {
	padding: 0px;
}

.magazine.magazine-widget {
	width: 100%;
}

.recommended-content .section-title.recommended-title {
	margin-top: 35px;
}

.dropdown-menu.active {
	overflow-x: visible;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 180px;
}

/*.dropdown-active {*/
	/*overflow-x: visible;*/
/*}*/

.navigation-container .side-menu.ios-menu-hook {
	max-width: 360px;
}

.nav.nav-stacked.ios-nav-hook {
	max-width: 180px;
}

.main-posts .main-big .post {
	min-height: 500px;
}

.table.table-drugs.table-drugs-classes tbody tr td:last-child {
	padding: 13px 25px 13px 20px;
}

.teaser {
	width: 100%;
	padding: 0 0;
	margin: 5px auto 0px auto;
}

.teaser a {
	color: #fff;
	font-size: 12px;
	line-height: 18px;
	margin: 0 0;
}

.teaser .feat {
	padding: 25px 0 20px 0;
	width: 140px;
	margin: auto;
}

.teaser .feat p {
	color: #fff;
	font-size: 12px;
	line-height: 18px;
	margin: 0 0;
}

.teaser .feat p a {
	padding: 15px 0 0;
	display: block;
}

.featured-menu {
	margin: 0px auto -7px auto;
}

.image-wrap.image-no-float {
	padding-bottom: 10px;
	/*padding-right: 24px;*/
}

.graphic-widget {
	color: #22221D;
}

.logo {
	max-width: 730px;
	background: none;
	width: auto;
}

.logo a {
	width: auto;
}

.header.header-scroll .logo {
	height: 35px;
	margin: 0px 30px 0px -30px;
}

.header.header-scroll .logo a {
	width: 100px;
}
.disable-head-transition .header {
    -moz-transition: none;
    -o-transition: color 0 ease-in;
    -webkit-transition: none;
    transition: none;
}

.video-thumbnail.video-thumbnail-list > a::after {
	top: 30%;
}

.credits {
	width: auto;
	max-width: 170px;
	padding: 0 0 0 10px;
	position: fixed;
	left: auto;
}

.footer {
	bottom: 150px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .img-full-with-bg {
    zoom: 1;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }
}

@supports (-ms-accelerator:true) {
	.img-full-with-bg {
		zoom: 1;
		filter: alpha(opacity=20);
		opacity: 0.2;
	}
}

.credits-not-mobile {
	height: 150px;
}

.itemMustRead img {
  width: 100%;
}

a p {
	color: #828282;
}

.full-img-without-scale {
	width: auto !important;
}

.img-full.imgslide-container .img-responsive {
	width: 100%;
}


.img-full.img-featured .img-responsive {
	width: 100%;
}

.size-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.head-page.head-page-static {
	margin: -5px 0px 20px 0px;
	position: relative;
}

/*#fb-root {*/
	/*display: none;*/
/*}*/

/*/!* To fill the container and nothing else *!/*/
/*.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {*/
	/*width: 100% !important;*/
/*}*/

.enable-scroll {
	overflow: hidden !important;
}

.navigation {
	padding: 10px 0 25px 0;
  margin-bottom: 120px;
}

.related-articles .media.media-recommend {
	float: left;
}

.header-title {
	width: 60%;
}

.clip-text-p {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.expand .wrpexp .cntexp .clkexp, .expand .wrpexp .clkexp {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.extra a {
	width: 35px;
	height: 111px;
	padding: 0px;
	background: url("../images/related.png")no-repeat;
}

.extra.close-sidebar a {
	width: 35px;
	height: 97px;
	padding: 0px;
	background: url("../images/close.png")no-repeat;
}

/*.fb-like.fb_iframe_widget iframe {*/
	/*width: 80px !important;*/
	/*height: 20px !important;*/
/*}*/

@media screen and (min-width: 1350px) {
	.dropdown-submenu > .dropdown-menu ul li {
		min-width: 1px!important;
		max-width: 190px;
	}
}

@media only screen and (max-width: 1349px) {
	.navigation-container {
		height: 100vh;
	}

	.related-articles .media.media-recommend {
		width: 50%;
	}

	.video-thumbnail.video-thumbnail-list > a::after {
		top: 25%;
	}

	.logo {
		max-width: 480px;
		width: auto;
	}

	.header.header-scroll .logo {
		margin: 0px 30px 0px 0px;
	}

	.wrapper-featured-img {
		min-height: 332px;
	}

	.dropdown-submenu > .dropdown-menu ul li {
		min-width: 1px!important;
		max-width: 190px;
	}

	.header-title {
		width: 50%;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1349px) {
	.video-listing .related-videos {
		padding-right: 24px;
	}

	.polls-index-hook {
		margin-bottom: 20px;
		/*padding-top: 270px;*/
	}

	.main-posts .main-big .post {
		min-height: 470px;
	}
}

/*@media only screen and (max-width: 1024px) {*/
	/*.main-posts .main-big .post {*/
		/*min-height: 475px;*/
	/*}*/
/*}*/


@media only screen and (min-width: 1023px) {
	.extra.close-sidebar {
		display: none !important;
	}
}

@media only screen and (max-width: 1023px) {
	.extra.close-sidebar {
		right: 367px;
	}

	.extra.while-scroll a {
		background: url("../images/sub.png") bottom 9px right 12px no-repeat;
		background-size: 10px auto;
		height: 35px;
	}

	.related-articles .media.media-recommend {
		width: 47%;
	}

	.img-full .img-responsive {
		width: initial;
	}

	.img-full.imgslide-container .full-img {
		width: 100%;
	}

	.img-full.img-featured .img-responsive {
		width: 100%;
	}

	.logo {
		max-width: 240px;
		width: auto;
	}

	.main-posts .main-big .post {
		min-height: 500px;
	}

	.wrapper-featured-img {
		min-height: 370px;
	}

	.related-videos, .video-detail > .related-articles {
		margin-left: -12px;
		margin-right: -12px;
	}

	.list-magazines {
		width: 100%;
		max-width: 720px;
	}

	.list-magazines .magazine {
		max-width: 100%;
		margin: 0 31px 30px 0;
	}

	.magazine {
		width: 30%;
		padding: 0px;
	}

	.list-magazines .magazine .cover {
		max-width: 100%;
		height: 380px;
	}

	.list-magazines .magazine .cover img {
		width: 233px;
	}


	.slide-img {
		height: 420px;
		width: 100%;
		position: relative;
	}

	.carousel-caption {
		top: 420px;
	}

    .sidebar-wrapper {
        max-width: none;
    }

	.video-listing .related-videos .item {
		padding-right: 10px;
		max-width: 46%;
		margin-right: 25px;
	}

	.video-listing .related-videos {
		margin: 0 -50px -30px 0;
		position: relative;
	}
}

/* for Ipad Retina portrait */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
	.full-img.main-featured-img.full-img-without-scale {
		max-height: 338px !important;
	}
}

/* for Ipad portrait */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
	.full-img.main-featured-img.full-img-without-scale {
		max-height: 338px !important;
	}

	.side-menu  > * {
		-webkit-transform: translateZ(0px);
	}

	.side-menu  > * {
		-webkit-transform: translate3d(0,0,0);
	}

	.navigation {
		margin: 0 0 150px 0;
		padding: 0 0 0px 0;
	}

	.content-magazines {
		padding-right: 0px;
	}

	.head-magazines.head-filter.out-container {
		width: -webkit-calc(100% - 13px);
		width: -moz-calc(100% - 13px);
		width: calc(100% - 13px);
	}

	.video-listing .related-videos .item {
		max-width: 45%;
	}

	/*.post-content img {*/
		/*min-width: 720px;*/
	/*}*/

	.slick-slider {
		max-width: 718px;
	}

	.slick-slider .slick-list {
		margin: 0px;
		max-width: 718px;
	}

	.slick-slider.two-slide {
		max-width: 720px;
	}

	.slick-slider.two-slide .slick-list {
		margin: 0px;
		max-width: 720px;
	}

	.slick-initialized .slick-slide {
		width: 335px;
	}

	.caption {
		margin-bottom: 20px;
	}

	.slick-slider .slick-list {
		left: 0px;
	}

	.item.itemMustRead.item3.slick-slide{
		margin-right: 0px !important;
	}

	.item.itemMustRead.slick-slide{
		width: 223px !important;
	}

	.item.itemHotTopic.item2.slick-slide{
		margin-right: 1px !important;
	}

	.item.itemHotTopic.slick-slide{
		width: 347px !important;
	}

	.sidebar.widgets-mobile {
		padding: 0px 10px 0;
	}

	/*.with-featured-image {*/
		/*padding-top: 0px;*/
	/*}*/

	#magazines-year {
		margin: 0px !important;
	}

	.modal-newsletter, .modal-personal {
		margin-top: 300px;
	}

	.credits {
		background-color: #f1f5e9;
	}

}

/* for Ipad pro portrait */
@media only screen
and (min-device-width : 1024px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    .slick-slider {
        max-width: 632px;
    }
    .slick-slider .slick-list {
        margin: 0px;
        max-width: 632px;
    }
    .slick-slider.two-slide {
        max-width: 632px;
    }
    .slick-slider.two-slide .slick-list {
        margin: 0px;
        max-width: 632px;
    }

    .slick-initialized .item.itemHotTopic.slick-slide {
        width: 303px !important;
    }
    .slick-initialized .item.itemMustRead.slick-slide {
        width: 194px !important;
    }
}

/* for Ipad landscape */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
	.side-menu  > * {
		-webkit-transform: translateZ(0px);
	}

	.side-menu  > * {
		-webkit-transform: translate3d(0,0,0);
	}

	.navigation {
		margin: 0 0 150px 0;
		padding: 0 0 0px 0;
	}

	.video-listing .related-videos .item {
		max-width: 46%;
	}

	/*.post-content img {*/
		/*max-width: 627px;*/
		/*width: 627px;*/
	/*}*/

	.post-content .img-full .imgslide-container img {
		max-width: 627px;
	}
	/*.item.itemHotTopic.slick-slide{*/
		/*width: 313.5px !important;*/
	/*}*/

	.item.itemHotTopic.item2.slick-slide{
		margin-right: 25px;
	}

	.modal-newsletter, .modal-personal {
		margin-top: 150px;
	}

	.credits {
		background-color: #f1f5e9;
	}
}

@media (max-width: 1024px) {
	.image-wrap.image-no-float {
		padding-bottom: 10px;
		padding-right: 0px;
		/*padding-right: 24px;*/
	}
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
	.header.header-scroll .container {
		padding-left: 0px;
		/*//max-width: 934px;*/
		margin-left: 90px;
	}
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
	.full-img.main-featured-img.full-img-without-scale {
		max-height: 338px !important;
	}
}


@media (max-width: 768px) {
	.extra.close-sidebar {
		right: 353px;
	}

	.video-listing .related-videos .item {
		padding-right: 0px;
		max-width: 45%;
	}

	.wrapper-featured-img {
		min-height: 332px;
	}

	/*.nav-pl {*/
		/*padding-left: 25px!important;*/
	/*}*/

	.nav-pl.sub-nav-pl {
		padding-left: 0px!important;
	}

    .nav-posl {
        position: relative;
        left: 0px;
        width: 90%;
    }

	.hr-always-visible {
		margin-bottom: 0px;
	}

	.text-posts .half-col:first-child .text-title {
		border-right: none;
	}

	.text-posts .half-col:first-child ul {
		border-right: none;
	}

	.slide-img {
		height: 420px;
		width: 100%;
		position: relative;
	}

	.carousel-caption {
		top: 420px;
	}

	.carousel-indicators {
		bottom: 10%;
	}

	.content-block .media {
		margin-top: 20px;
		padding-bottom: 20px;
	}

	.dropdown-submenu > .dropdown-menu ul li {
		/*min-width: 190px!important;*/
		max-width: none!important;
	}
}

@media only screen and (max-width: 767px) {
	.ad-block.ad-middle.last {
		padding: 25px 0px 5px;
	}

	.ad-block.ad-middle.last span {
		top: 5px;
	}

  .sidebar-active .sidebar-wrapper {
    overflow-x: hidden;
  }

	.extra.close-sidebar {
		right: 304px;
	}

	.dropdown-submenu > .dropdown-menu ul {
		padding: 70px 0 0;
	}

	.logo {
		height: 46px;
		margin: 0px;
	}

	.logo .img-logo {
		max-height: 46px;
		opacity: 0;
	}


	.dropdown-active .dropdown-submenu > .dropdown-menu.active {
		width: 100%;
	}

	.credits {
		background-color: #f2f2f2 !important;
	}

	.related-articles .media.media-recommend {
		width: 100%;
	}

	.navigation ul.nav li:hover > a {
		padding-left: 18px;
	}

	.navigation ul.nav li:hover ul li a {
		padding-left: 18px;
	}

	.footer {
		position: fixed;
		max-width: 500px;
		margin: 0px auto;
		bottom: 1px;
		top: initial;
	}

	.nav.nav-stacked.ios-nav-hook {
		max-width: 100%;
	}

	.navigation-container .side-menu.ios-menu-hook {
		max-width: 100%;
	}

	.credits {
		width: 100%;
		text-align: center;
		padding: 0 40px;
		position: relative;
		left: auto;
		bottom: auto;
		max-width: 330px;
		margin: 0 auto;
		background-color: #f2f2f2;
	}

	.video-thumbnail.video-thumbnail-list > a::after {
		top: 35%;
	}

	.logo a {
		width: 116px;
		height: 100%;
	}
	.modal-adv button.close {
		font-size: 40px;
		padding-bottom: 30px;
		margin: -15px 0px 0px 0px;
	}

	.related-articles .media-body.media-body-recommended {
		border: none;
	}

	.magazine {
		width: 100%;
	}

	.video-listing .related-videos {
		margin: 0 0 -20px;
	}

	.video-listing .related-videos .item {
		padding: 0 0 16px;
		border-bottom: 1px solid #ededed;
		margin: 0 0 25px 0;
		width: 100%;
		max-width: 100%;
		margin-right: 0;
		clear: both;
	}
}

@media only screen and (max-width: 768px) {
	.modal-dialog.share-modal button.close {
		margin: 0px;
		font-size: 40px;
	}

	.modal.modal-slideshow.modal-slideshow-0.in {
		background: rgba(18, 18, 18, 1);
	}

	#magazines-year {
		margin-bottom: 20px;
		margin-left: 0px;
	}

	.teaser {
		width: auto;
		margin: 0px 20px;
	}
	.teaser .feat p
	{
		max-width: 150px;
	}

	.form-control.custom-selectnojs.sort-blog {
		width: 110px;
		margin: 0 0 0 25px !important;
	}

	#select-years-magazine {
		margin-left: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.visible-xs.hidden {
		display: none !important;
	}

	.navigation ul.nav li:hover ul.with-top-elem li:first-child a {
		 padding-left: 18px;
	 }

	.navigation ul.nav li:hover ul.with-top-elem li a {
		padding-left: 26px;
	}

	.navigation ul.nav li ul li:first-child a {
		padding-left: 18px;
	}

	.navigation ul.nav li ul li a {
		padding-left: 26px;
	}

	.back-menu {
		margin-top: 15px;
		background: #363636 url("../images/svg/back-menu.svg") bottom left 40px no-repeat;
		height: 40px;
	}

	.dropdown-submenu > .dropdown-menu ul {
		padding: 70px 0 0;
	}

	.overflow-div {
		margin-top: -18px;
	}

	.credits-mobile {
		margin-bottom: 20px;
	}

	.navigation {
		padding: 10px 0 0 0;
	}

	.teaser .feat {
		margin: 0px;
	}

	.navigation-container {
		padding: 50px 0 0 0;
	}

	.page-nav.page-nav-top.page-nav-months {
		margin-top: 40px;
	}

	.related-articles .media.media-recommend.add-more-recommend-content {
		display: none;
	}

	.clip-blogger-description {
		height: 100%;
	}

	#video-sort {
		margin-left: 0px;
	}

	.text-posts .half-col .text-title {
		border: none !important;
	}

	.text-posts .half-col ul {
		border: none !important;
	}

	.sidebar-active .sidebar {
		width: 100%;
		padding: 25px 5px 5px 5px;
		max-width: 285px;
	}

	.head-page .share-actions {
		padding: 13px 0px 0px;
	}

	.head-page .share-actions-slideshow {
		margin-left: 10px;
	}

	.modal.in .modal-dialog.share-modal {
		margin-top: 25px;
		margin-left: 25px;
		margin-right: 25px;
	}

	.share-parent .modal-content {
		max-width: 400px;
		margin: auto;
	}
}


@media only screen and (max-width: 375px) {
	.full-img.main-featured-img.full-img-without-scale.full-width {
		width: 100% !important;
		max-width: 338px !important;
	}
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {
	.full-img.main-featured-img.full-img-without-scale {
		max-height: 338px !important;
	}

	.credits-mobile {
		margin-bottom: 0px;
		height: 68px;
	}

	.navigation-container {
		padding: 60px 0 0 0;
	}

	.navigation {
		padding: 0px;
	}

	.back-menu {
		margin-top: 5px;
		background: #363636 url("../images/svg/back-menu.svg") bottom left 40px no-repeat;
		height: 40px;
	}

	.dropdown-submenu > .dropdown-menu ul {
		padding: 60px 0 0;
	}

	.social-widget {
		width: 270px;
	}

	.social-widget .fb_iframe_widget_fluid span {
		width: 270px !important;
	}

	.scroll-wrapper-widget {
		width: 275px;
	}

	/*.post-content img {*/
		/*min-width: 280px;*/
	/*}*/

	/*.image-wrap.image-no-float {*/
		/*margin-top: 15px;*/
	/*}*/

	.fb-like {
		margin-right: 10px;
	}

	.head-page .share-actions li.share {
		margin-left: -10px;
	}

	.form-control.custom-selectnojs.sort-blog {
		width: 110px;
		margin: 0 0 0 25px !important;
	}
}

/* iPhone 6/6s plus both */
@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px) {
	.extra.close-sidebar {
		right: 287px;
	}
}

/* iPhone 6/6s both */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
	.extra.close-sidebar {
		right: 287px;
	}
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
	.full-img-without-scale {
		max-height: 338px !important;
	}
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
	.extra.close-sidebar {
		right: 287px;
	}
}

@media only screen and (max-width: 375px) {
	.extra.close-sidebar {
		right: 283px;
	}

	.navigation {
		margin: 0 0 0px 0;
	}

	.featured-menu {
		margin: 0px auto 0px auto;
	}

	.dropdown-submenu > .dropdown-menu ul li {
		min-width: 190px!important;
		max-width: none!important;
	}

	.dropdown-active .dropdown-submenu > .dropdown-menu.active {
		left: 0;
		width: 100%;
	}

	.modal-adv button.close {
		font-size: 40px;
		padding-bottom: 0px;
		margin: -15px 0px 0px 0px;
	}

	.carousel-indicators li, .carousel-indicators .active {
		margin-left: 5px;
		margin-right: 5px;
	}

	.wrapper-featured-img {
		min-height: 229px;
	}

	.modal-adv-header {
		margin-bottom: -5px;
		padding-bottom: 0px;
	}

	.content-sub-block {
		height: 100%;
	}

	.video-description {
		height: 80px;
	}

	.form-control.custom-selectnojs.filter-blogger {
		margin-left: 0px;
	}

	.magazine {
		width: 100%;
	}

	.fb-like {
		top: -6px;
	}

	.launch-slide {
		top: 50%;
	}
	.slide-img {
		height: 190px;
		width: 100%;
		position: relative;
	}

	.carousel-caption {
		top: 190px;
	}

	.carousel-indicators {
		bottom: 10%;
	}

	.modal-customize{
		max-width: 400px;
	}

	#close-customize{
		top: 0px;
		left: 90%;
	}

	.page-nav.page-nav-top {
		margin-top: 0px;
	}

	.modal-width {
		width: 94%;
	}

	.fb-like {
		margin-right: 10px;
	}

	.head-page .share-actions li.share {
		margin-left: -10px;
	}

	.content-block .media {
		margin-top: 20px;
		padding-bottom: 20px;
	}

	.video-thumbnail.video-thumbnail-list>a:after {
		top: 30%;
	}

	.video-thumbnail.video-thumbnail-slideshow>a:after {
		top: 40%;
	}

	.modal.in .modal-dialog.share-modal {
		margin-top: 25px;
		margin-left: 25px;
		margin-right: 25px;
	}

	.modal-dialog-slideshow {
		margin: 50px auto 0px auto;
	}

	.modal-dialog button.close.close-slideshow {
		font-weight: 100;
		font-size: 28px;
		margin: -40px 20px 20px 0px;
	}

	.slideshow-indicators {
		bottom: -30%;
	}

	.soc-icon-col {
		margin-bottom: 40px;
	}

	.soc-text {
		padding-top: 0px;
	}

	.soc-icons {
		margin-left: 0px;
		margin-right: 0px;
	}

	.soc-link {
		height: 66px;
		width: 66px;
	}

	.soc-icon {
		height: 66px;
		width: 66px;
	}

	.div-soc-icon {
		height: 66px;
		width: 66px;
	}

	.clip-text-p {
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	#select-years-magazine {
		margin-left: 0px;
	}

	/*.page-nav ul li.page-nav-current a {*/
		/*padding-top: 6px;*/
	/*}*/

	.page-nav ul li a span.page-nav-title {
		white-space: normal;
	}

	.logo {
		max-width: 180px;
		width: auto;
	}

}

@media only screen and (max-width: 337px) {
  .page-nav ul li.page-nav-current a {
    padding-top: 6px;
  }
}

@media only screen and (max-width: 320px) {

	.logo {
		max-width: 150px;
		width: auto;
	}

	.video-thumbnail.video-thumbnail-list > a:after {
		top: 23%;
	}

}

.modal {
    z-index: 3050;
}

.alert {
	margin-top: 0px;
}

.hr-without-margin-top {
	margin-top: 0px;
}

.hr-without-margin-bottom {
	margin-bottom: 0px;
}

.hr-without-margin {
	margin: 0px;
}

.table thead tr th {
	word-wrap: break-word;
	white-space: normal;
}

.table > thead > tr > th {
	vertical-align: middle;
}

.table {
	/*table-layout: fixed;*/
	width: 100%;
}
.table.hidden-xs {
    table-layout: fixed;
}
.table.hidden-xs.table-auto {
    table-layout: auto;
}
.table.hidden-xs tbody tr td a {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
}


.hook-spacing {
	margin-top: -20px;
}

.hook-add-spacing {
	margin-bottom: 20px;
}

.hook-add-margin-top-25px {
	margin-top: 25px;
}

.hook-add-margin-top-10px {
	margin-top: 10px;
}

.card-small .card .media-object {
	width: 115px;
	height: 115px;
}

.card .author-details .media-object {
	width: 115px;
	height: 115px;
}

#gridSystemModalLabel {
	float: left;
}

#soc-buttons-header {
	padding-right: 25px;
}

#gridSystemModalLabel {
	color: #fff;
}

.close {
	font-size: 25px;
}

.navbar-title {
	color: #000000;
}

.dropdown-submenu .subnavbar-title {
    color: #FFFFFF;
    opacity: 0.5;
}

.media-body.magazine-media-list .post-meta .author {
	display: block;
	margin-bottom: 5px;
}

.event-info {
	margin: 25px 0px 25px;
}

.event-info .label-detail > span,
.event-info .label-detail a {
	word-wrap: break-word;
}

.padding-right {
	padding-right: 15px;
}

.slider-without-border-bottom {
	border-bottom: none !important;
}

.share-fix {
	padding: 0px;
}

.btn.custom-height {
	height: 50px;
}

.slick-slider {
	border-bottom: none;
}

.navigation ul.nav li > a {
    padding: 14px 0 14px 20px;
}

.social-widget-spacing {
  margin-bottom: 30px;
  margin-top: -2px;
}

.graphic-widget {
    width: 245px;
}

.bar-wrapper {
    width: 80%!important;
    margin: 5px 0 0 15px!important;
}

.ad-block img {
  max-width:100%;
  height: auto;
}
span.last-revised {
  color: #b4b4b4;
  font-size: 13px;
}

.ad-block div {
  display:block !important;
  width: 100% !important;
}

.ad-block div.size728.emds-banner{
    width: 728px !important;
}
.ad-block div.size300M.emds-banner,
.ad-block div.size300.emds-banner {
    width: 300px !important;
}
.ad-block div .emds-banner-corner-tag{
    width: 45px !important;
}

.ad-block div .emds-banner-cta-button{
    width: 151px !important;
}

.ad-block div .emds-banner-text-main{
    width: 308px !important;
}
.ad-block div .emds-banner-copyright{
    width: 316px !important;
}
.ad-block div a.emds-banner-hotspot {
    z-index: 2;
}
.ad-block div.size728.emds-banner {
    max-width: 100%;
    background-position-x: 0;
}
/*.ad-block.ad-middle div.emds-banner {*/
    /*opacity: 1 !important;*/
/*}*/
.ad-block div.size300 .emds-banner-cta-button,
.ad-block div.size300M .emds-banner-cta-button {
    width: 300px !important;
}


.navigation-container {
  height:10px;
}

.ad-block.ad-billboard {
  padding: 15px 0;
}
.ad-block.ad-billboard span {
  top: -6px;
}

.post-content img { margin: 20px }
.post-content img.img-responsive {
  margin:0;
}

div.post-content .gsc-control-cse {
  line-height: normal;
}
div.post-content .gsc-control-cse * {
  box-sizing: content-box;
}
.post-content img.gs-image,
.post-content img.gsc-getlink-image {
  margin: 0;
}
.video-detail .video-content > p {
  padding: 0;
  line-height: 36px;
  font-size: 16px;
}
.video-content p,
.post-content p {
  margin: 0;
}
.video-detail .video-content > p,
.content-block.extended .post-content > p {
  margin: 0;
}
.video-detail .video-content p:empty,
div.post-content p:empty, .content-block.extended .post-content > p:empty {
  margin-bottom: 29px;
}

.post-content .gsc-getlink-container {
  padding-top: 0;
}
.gsc-table-result {
  margin: 0 8px;
}

.content .ad-block div:first-child, .content .ad-block div:first-child div:first-child, .content .ad-block div:first-child div:first-child div:first-child {
  margin: 0 auto;
  /*max-width: 100%;*/
}

.ad-block, .ad-block div {
  overflow: visible !important;
  /*overflow: hidden !important;*/
  z-index: 1 !important;
}

.content .ad-block div iframe {
  /*transform-origin: top left;*/
}
.content .ad-block div iframe.not-scalable {
  transform: scale(1) !important;
  left: 0 !important;
}

.content .ad-block ins {
  /*max-width: 100%;*/
}
.content .ad-block > ins[data-dcm-rendering-mode=script] {
  max-width: 100% !important;
}

@media only screen and (max-width: 728px) {
  .content #ad_top.ad-block div iframe {
    margin-left: -20px !important;
  }
  .content #ad_top.ad-block div iframe.not-scalable {
    margin: 0 auto !important;
  }
}
@media only screen and (max-width: 480px) {
  .content #ad_top.ad-block div iframe {
    margin: 0 auto !important;
  }
  .ad-block.ad-top {
    margin: 0 -20px;
  }
  #ad-middle-container.ad-block span {
    /*margin: 0;*/
  }
  #ad-middle-container.ad-block.space.last span {
    margin: 0 0 0 -35px;
  }
}
@media only screen and (min-width: 481px) {
  .content .ad-block ins iframe {
    position: static !important;
  }
  .content .ad-block div iframe {
    position: static !important;
  }
}
@media only screen and (max-width: 767px) {
	.content .ad-block div.GoogleActiveViewClass.GoogleActiveViewElement iframe {
		position: static !important;
	}
}

#lazy-container .media a.pull-left img.media-object,
.content-block .media a.pull-left img.media-object {
  max-width: 100px;
  width: auto;
}

:target:before {
  content:"";
  display:block;
  height: 90px; /* fixed header height*/
  margin: -90px 0 0; /* negative fixed header height */
}

.from-indicator {
  width: 320px;
}
.from-indicator .info {
  width: 240px;
}
.from-indicator .info .name {
  display: block;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.table tbody tr td a {
  display: inline;
}

.ad-block span {
  position: static;
}

.ad-block span div {
  margin-top: -20px;
}

.ad-block span.ad-label {
  position: absolute;
}

.table tbody tr td p {
  white-space: normal;
}

@media only screen and (max-width: 480px) {
  .table {
    table-layout: fixed;
  }
  .table tbody tr td a {
    word-wrap: break-word;
  }
  .list-magazines .magazine .cover img {
    margin: 0 auto;
  }
}

/*@media only screen and (max-width: 1024px) {*/
  table.table .image-wrap .img-responsive {
    max-width: none;
  }
/*}*/

@media only screen and (max-width: 1024px) {
  .header.header-scroll .header-title {
    width: 45%;
  }
}
@media only screen and (max-width: 900px) {
  .header.header-scroll .header-title {
    width: 41%;
  }
}
@media only screen and (max-width: 850px) {
  .header.header-scroll .header-title {
    width: 37%;
  }
}
@media only screen and (max-width: 800px) {
  .header.header-scroll .header-title {
    width: 35%;
  }
}
@media only screen and (max-width: 768px) {
	.ml-45-not-xs {
		margin-left: 0 !important;
	}
}

@media only screen and (min-width: 1024px) {
  .wide-logo {
    margin-top: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .not-wide-logo {
    margin-top: 0 !important;
  }
}

.mb-20 {
  margin-bottom: -20px !important;
}
.pt10 {
  padding-top:10px !important;
}
.mt-15 {
  margin-top: -15px !important;
}
.ad-space-mobile-fix {
  width:100%;height:1px;margin-bottom:24px;
}
.mt-30 {
  margin-top: -30px !important;
}
.display-none {
  display:none;
}
.current-issue-img {
  width: 184px; height: 244px; margin: 0 auto;
}
.social-media-box-fix {
  padding-left:0 !important;
  padding-right:0 !important;
  padding-bottom:0 !important;
  margin-bottom:0 !important;
}
.time-capsule-fix {
  width: 184px; height: 244px;
}
.bg-white-color {
  background-color: #ffffff !important;
}
.set-333-color {
  color: #333 !important;
}
.mt-25 {
  margin-top: -25px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt-20 {
  margin-top: -20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px;
}
.visibility-hidden {
  visibility: hidden;
}
.mt10 {
  margin-top: 10px !important;
}
.bb-med-none {
  border-bottom: medium none !important;
}
.pt0 {
  padding-top:0 !important;
}
.pb20 {
  padding-bottom:20px !important;
}
.survey-iframe-fix {
  height:1600px;width:100%;
}
.mt-5 {
  margin-top: -5px !important;
}
.mt5 {
  margin-top: 5px !important;
}

.table.table-drugs tbody tr td p {
  line-height: 20px;
  font-size: 15px;
  margin: 0;
}

.blogger-image {
	position: absolute;
	width: 50px;
	top: 10px;
	left: 8px;
	border-radius: 50%;
}

/*
JOHNLA ADDED LAZY LOAD Loading animation
 */

.spinner {
	width: 100px;
	height: 50px;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	text-align: center;
	display:none;
}
.spinner .ball {
	width: 20px;
	height: 20px;
	background-color: #333;
	border-radius: 50%;
	display: inline-block;
	-webkit-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.spinner p {
	color: #333;
	margin-top: 5px;
	/*   font-family: sans-serif;*/
	letter-spacing: 3px;
	font-size: 10px;
}

@-webkit-keyframes motion {
	0% {
		-webkit-transform: translateX(0) scale(1);
		transform: translateX(0) scale(1);
	}
	25% {
		-webkit-transform: translateX(-50px) scale(0.3);
		transform: translateX(-50px) scale(0.3);
	}
	50% {
		-webkit-transform: translateX(0) scale(1);
		transform: translateX(0) scale(1);
	}
	75% {
		-webkit-transform: translateX(50px) scale(0.3);
		transform: translateX(50px) scale(0.3);
	}
	100% {
		-webkit-transform: translateX(0) scale(1);
		transform: translateX(0) scale(1);
	}
}

@keyframes motion {
	0% {
		-webkit-transform: translateX(0) scale(1);
		transform: translateX(0) scale(1);
	}
	25% {
		-webkit-transform: translateX(-50px) scale(0.3);
		transform: translateX(-50px) scale(0.3);
	}
	50% {
		-webkit-transform: translateX(0) scale(1);
		transform: translateX(0) scale(1);
	}
	75% {
		-webkit-transform: translateX(50px) scale(0.3);
		transform: translateX(50px) scale(0.3);
	}
	100% {
		-webkit-transform: translateX(0) scale(1);
		transform: translateX(0) scale(1);
	}
}

.ml-45-not-xs {
	margin-left: 45px;
}

.post-content em {
  /*font-family: "tradegothiclt";*/
  font-style: italic;
}
.post-content em strong {
  font-style: italic;
}
.post-content strong em {
  font-weight: 600;
}

.catalog-page h2.sub-title {
  margin-top:55px;
  font-size: 2.0rem;
  line-height: 2.6rem;
}
.catalog-page .list-magazines.two-options,
.catalog-page .list-magazines.bottom-items {
  padding-top: 40px;
}
.catalog-page .list-magazines.bottom-items {
  margin-bottom: 20px;
}
.catalog-page .list-magazines.two-options .magazine {
  max-width: 300px;
  width: 300px;
  margin-right: 70px;
}
.catalog-page .list-magazines.two-options .magazine .cover {
  max-width: 300px;
  width: 300px;
  height: 482px;
}
.catalog-page .list-magazines.two-options .magazine .cover img {
  max-width: 300px;
  width: 300px;
}
.catalog-page .list-magazines.bottom-items .sub-link {
  color: #363636;
  font-size: 12px;
}
.catalog-page .list-magazines.bottom-items .magazine .cover {
  height: 355px;
}

.catalog-page .list-magazines .magazine.selected img {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 4px solid #f48a31;
}
.catalog-page .list-magazines .magazine.selected:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  background-color: #f48a31;
  background-image: url("../images/directory/checkmark.png");
  background-repeat: no-repeat;
  background-position: 5px 7px;
}

@media only screen and (max-width: 1349px) {
  .catalog-page .list-magazines .magazine {
    max-width: 185px;
    margin-right: 58px;
  }
  .catalog-page .list-magazines .magazine .cover {
    height: 330px;
  }
  .catalog-page h2.sub-title {
    margin-top: 30px;
  }
  .catalog-page .list-magazines {
    margin-top: 0;
  }
  .catalog-page .list-magazines.bottom-items {
    margin-bottom: 45px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1349px) {
  .catalog-page .list-magazines .magazine {
    margin-right: 38px;
  }
  .catalog-page .list-magazines.two-options .magazine {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .catalog-page .list-magazines {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .catalog-page .list-magazines .magazine,
  .catalog-page .list-magazines.two-options .magazine,
  .catalog-page .list-magazines.bottom-items .magazine,
  .catalog-page .list-magazines .magazine .cover,
  .catalog-page .list-magazines.two-options .magazine .cover,
  .catalog-page .list-magazines.bottom-items .magazine .cover,
  .catalog-page .list-magazines .magazine .cover img,
  .catalog-page .list-magazines.two-options .magazine .cover img,
  .catalog-page .list-magazines.bottom-items .magazine .cover img {
    max-width: calc(50vw - 25px);
    width: calc(50vw - 25px);
    padding-right: 0;
    margin-right: 0;
  }
  .catalog-page .list-magazines.two-options .magazine .cover,
  .catalog-page .list-magazines .magazine .cover,
  .catalog-page .list-magazines.bottom-items .magazine .cover {
    height: auto;
  }
  .catalog-page .list-magazines .magazine {
    float: left;
    clear: none;
  }
  .catalog-page .list-magazines .magazine:nth-child(odd) {
    margin-right: 10px !important;
  }
  .catalog-page .list-magazines {
    max-width: 730px;
  }
  .catalog-page .list-magazines .magazine.selected:after {
    width: 20px;
    height: 20px;
    background-size: 70%;
    background-position: 3px 6px;
  }
  .catalog-page .list-magazines.two-options .magazine .cover,
  .catalog-page .list-magazines .magazine .cover {
    min-height: 265px;
  }
  .catalog-page .list-magazines.bottom-items .magazine .cover {
    min-height: 300px;
  }
}

.directory-search {
  margin-top: 25px;
  margin-right: 25px;
}
.directory-search .form-control {
  padding: 10px 20px;
  height: 44px;
}
.directory-search .btn-primary {
  margin: 15px 0 10px 0;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .directory-search {
    margin-right: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .directory-search {
    margin-right: 0;
    padding-right: 0;
  }
}

.customCheckbox {
  width: 17px;
  position: relative;
  margin: 0 10px 0 0;
  display: inline-block;
}
.customCheckbox label {
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: url("/images/checkbox.png");
}
.customCheckbox label:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 6px;
  left: 4px;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.no-touch .customCheckbox label:hover::after {
  opacity: 0.5;
}
.customCheckbox input[type=checkbox] {
  visibility: hidden;
  margin: 0;
}
.customCheckbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}

.ms-choice {
  border: 1px solid #adadad;
  border-radius: 0;
  color: #adadad;
  height: 28px;
  background: #f9f9f9;
  background: -webkit-linear-gradient(top, #ffffff 0%, #f9f9f9 60%, #e7e7e7 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f9f9f9 60%, #e7e7e7 100%);
}
.ms-choice > div {
  display: block;
  width: 20px;
  height: 28px;
  border: 1px solid #adadad;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eeeeee;
  background-image: url("../js/vendor/multiple-select/dropdown-arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  background: url("../js/vendor/multiple-select/dropdown-arrow.png") no-repeat center, -webkit-linear-gradient(top, #eeeeee 0%, #dcdcdc 100%);
  background: url("../js/vendor/multiple-select/dropdown-arrow.png") no-repeat center, linear-gradient(to bottom, #eeeeee 0%, #dcdcdc 100%);
}
.ms-choice > div.open {
  background: url("../js/vendor/multiple-select/dropdown-arrow-up.png") no-repeat center, -webkit-linear-gradient(top, #eeeeee 0%, #dcdcdc 100%);
  background: url("../js/vendor/multiple-select/dropdown-arrow-up.png") no-repeat center, linear-gradient(to bottom, #eeeeee 0%, #dcdcdc 100%);
}
.ms-choice > span.placeholder {
  color: #adadad;
}
.ms-drop {
  border-radius: 0;
  border: 1px solid #adadad;
  color: #adadad;
}
.ms-drop ul {
  padding: 0;
}
.ms-drop ul li {
  padding: 5px 10px 3px;
}
.no-touch .ms-drop ul li:hover {
  background: #eeeeee;
}
.ms-drop ul > li label {
  line-height: 16px;
}
.ms-drop ul li.inside-group {
  padding-left: 25px;
}
.ms-drop ul > li.group {
  position: relative;
}
.ms-drop,
.ms-drop ul > li label span,
.ms-drop ul > li.group,
.ms-drop ul > li label,
.ms-drop ul li {
	color: #5d5d5d;
}
.collapse-group {
  position: absolute;
  background: url("../js/vendor/multiple-select/dropdown-arrow-up.png") no-repeat center;
  width:30px;
  height: 28px;
  top: 0;
  right: 0;
  cursor: pointer;
}
.collapse-group.closed {
  background: url("../js/vendor/multiple-select/dropdown-arrow.png") no-repeat right 11px center;
  width: 100%;
}

.directory-result-page .content {
  width: 100%;
}
.directory-search-result {
  margin-top: 25px;
}
.directory-search-result .form-group.search,
.directory-search-result .form-group.dropdown {
  float: left;
}
.directory-search-result .form-group.search {
  width: 240px;
}
.directory-search-result .form-group.dropdown.distance-search {
  width: 104px;
}
.directory-search-result .form-group.dropdown {
  margin-left: 8px;
  width: 227px;
}
.directory-search-result .form-group.dropdown select {
  width: 100%;
}
.directory-search-result .results {
  margin-top: 15px;
  margin-bottom: 75px;
}
.directory-search-result .results .map {
  margin-left: 8px;
  height: 550px;
  width: 695px;
  float: left;
  margin-top: 0;
  padding: 0;
}
.directory-search-result .items-list {
  width: 350px;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  padding: 4px 0 0 0;
  float: left;
  margin: 0;
}
.directory-search-result .items-list ul {
  padding: 0 20px;
  margin: 0;
  list-style: none;
  height: 545px;
  overflow-y: auto;
  overflow-x: hidden;
}
.directory-search-result .items-list ul li {
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
}
.directory-search-result .items-list ul li:last-child {
  border: none;
}
.directory-search-result .items-list ul img {
  max-width: 45px;
  max-height: 45px;
  float: left;
}
.directory-search-result .items-list ul p.item-description {
  padding-left: 65px;
  margin: 0;
}
.directory-search-result .items-list ul a p.item-description {
  padding-top: 0;
  line-height: 23px;
  min-height: 45px;
}
.single-dropdown,
.location-dropdown,
.singleSelect-item,
.singleSelect-miles,
.multipleSelect-type,
.multipleSelect-services,
.multipleSelect-filters {
  width: 100% !important;
}
.directory-search-result .form-group.search input {
  background-image: url("../js/vendor/multiple-select/multiple-select.png");
  background-repeat: no-repeat;
  background-position: right 4px top -20px;
}
#load-more,
.map-results-tabs,
#directory-show-filters,
#dropdown-filters .close-it,
#dropdown-filters .btn-primary {
  display: none;
}
.ms-drop ul {
  overflow-x: hidden;
}
.ms-drop ul > li label {
  white-space: pre-wrap;
}
.ms-drop ul > li label span {
  display: inline-block;
  width: calc(100% - 28px);
}
.customCheckbox {
  display: block;
  float: left;
}
.form-group.zip-search {
  float: left;
  width: calc(100% - 152px);
}
.form-group.distance-search {
  float: right;
  width: 140px;
}
.singleSelect-miles .ms-choice > div,
.singleSelect-miles .ms-choice {
  height: 44px;
}
.singleSelect-miles .ms-choice > span {
  padding-left: 10px;
  top: 9px;
}
.search-wrapper {
  width: 100%;
  height: 44px;
}
.directory-search-result .singleSelect-miles .ms-choice > div,
.directory-search-result .singleSelect-miles .ms-choice {
  height: 28px;
}
.directory-search-result .singleSelect-miles .ms-choice > span {
  padding-left: 8px;
  top: 0px;
}
.search-wrapper-results {
  display: inline;
}
.directory-no-results-found {
  padding: 20px;
}

@media only screen and (min-width: 1025px) and (max-width: 1349px) {
  .directory-search-result .form-group.search {
    width: 240px;
  }
  .directory-search-result .form-group.dropdown {
    width: 200px;
  }
  .directory-search-result .results .map {
    width: 617px;
  }
  .directory-search-result .form-group.dropdown.last-item {
    width: calc(100% - 777px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .directory-search-result .width-hook-tablet {
    max-width: calc(100vw - 24px)
  }
  .directory-search-result .form-group.search {
    width: calc(15%);
  }
  .directory-search-result .form-group.dropdown.distance-search {
    width: calc(13% - 8px);
  }
  .directory-search-result .form-group.dropdown {
    width: calc(24% - 8px);
  }
  .directory-search-result .results .map,
  .directory-search-result .items-list {
    width: calc(50% - 4px);
    /*width: calc(50vw - 28px);*/
  }
  .directory-search-result .results {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1350px) and (max-width: 1370px) {
    .directory-search-result .form-group.dropdown.last-item {
        width: calc(100% - 830px);
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1370px) {
  .directory-search-result .results .map {
    width: calc(100% - 358px);
  }
}
@media only screen and (max-width: 767px) {
  .directory-search-result .items-list ul {
    overflow-y: hidden;
  }
  .search-wrapper-results .form-group,
  .directory-search-result .form-group.dropdown.distance-search {
    margin-bottom: 0;
  }
  .search-wrapper-results {
    display: block;
    height: 28px;
  }
  .single-dropdown .ms-choice > span,
  .location-dropdown .ms-choice > span,
  .singleSelect-miles .ms-choice > span {
    font-size: 14px;
  }
  .directory-search.custom-search .form-control,
  .form-group.zip-search {
    width: 100%;
    float: none;
  }
  .directory-search.custom-search .btn-primary {
    width: 100%;
    float: none;
  }
  .form-group.distance-search {
    width: 95px;
  }
  .directory-search-result .form-group.search {
    width: calc(100% - 108px);
  }
  .directory-search-result .form-group.dropdown.distance-search {
    width: 100px;
    float: right;
  }
  body.directory-filters-opened {
    overflow-y: hidden;
  }
  #dropdown-filters {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    right: -100%;
    z-index: 9990;
    padding: 25px 20px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .directory-filters-opened #dropdown-filters {
    right: 0;
  }
  .directory-search-result .form-group.dropdown {
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 15px;
  }
  #dropdown-filters .btn-primary {
    display: block;
    position: absolute;
    margin: 0 0 0 -65px;
    padding: 9px 11px 11px;
    /*bottom: 25px;*/
    left: 50%;
		top: 191px;
  }
  #dropdown-filters .close-it {
    display: block;
    width: 17px;
    height: 17px;
    background: url("../images/directory/x-icon.png");
    float: right;
    margin-bottom: 15px;
  }
  #directory-show-filters {
    /*font-family: "tradegothicno2bold";*/
    color: #363636;
    display: block;
    font-size: 14px;
    /*letter-spacing: 0.5px;*/
    background: url("../images/directory/filter-icon.png") no-repeat left center;
    padding-left: 16px;
    float: right;
		background-position-y: 5px;
    font-weight: 600;
  }
  p.inline-m {
    display: inline-block;
  }
  .directory-search-result {
    margin-top: 10px;
  }
  .directory-search-result .items-list.box:before {
    display: none;
  }
  .directory-search-result .items-list {
    padding: 0;
    border: none;
    width: 100%;
    float:none;
  }
  .directory-search-result .items-list ul {
    height: auto;
    padding: 0;
  }
  .directory-search-result .items-list ul li a {
    display: block;
    margin: 0 20px 0 20px;
  }
  .map-results-tabs {
    display: block;
    clear: both;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  .map-results-tabs.tabs ul.nav-tabs li {
    width:50%;
  }
  .directory-search-result .results .map {
    float: none;
    margin-left: -20px;
    width: calc(100vw);
    height: 340px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .directory-search-result .results .map.mobile-trick {
    display: none;
  }
  .directory-search-result .items-list ul li.hide-m {
    display: none;
  }
  .directory-search-result .results {
    margin-bottom: 20px;
  }
  #load-more.show-it {
    display: block;
    margin: 25px auto 0;
  }
}

.checkout-proceed-button {
	height: 60px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99;
	opacity: 0.95;
}

.checkout-proceed-button .checkout-proceed-button-text {
	color: #fff;
	margin: 0 auto;
	display: block;
	width: 260px;
	height: 35px;
	margin-top: 12.5px;
	padding-top: 5px;
	border: 2px solid #fff;
	text-transform: uppercase;
	cursor: pointer;
}

.provider-detail-page .form-group a {
  font-size: 16px;
  position: static;
  color: #b4b4b4;
}

.provider-detail-page .item-detail .info .name.name-provider {
	font-size: 1.7rem;
}

.provider-detail-page .item-detail .info .name.name-provider span {
	color: #828282;
	line-height: 23px;
	font-size: 1.08rem;
}

.provider-detail-page .expand .wrpexp .clkexp.provider-info-label {
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	font-weight: bold;
	margin-bottom: 0;
}

.provider-detail-page .expand .wrpexp .clkexp.provider-info-label {
	background-image: none;
  color: #fff;
  /*font-family: "tradegothicno2bold";*/
  font-size: 14px;
  font-style: normal;
  padding: 0 0 0 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
	height: 40px;
	line-height: 40px;
  display: block;
	padding-top: 12px;
	padding-bottom: 12px;
	line-height: 18px;
}


.provider-detail-page .provider-info-wrapper .map {
	height: 245px;
  margin-top: 0;
}

.provider-detail-page .provider-label {
	font-weight: normal;
  font-family: "tradegothicno2bold";
}

.provider-detail-page .provider-item-info {
  font-size: 16px;
	margin-bottom: 17px;
}

.provider-detail-page .post-filter.provider-info-wrapper {
	margin-top: 15px;
}

.provider-block .provider-map-wrapper,
.provider-block .col-xs-12,
.provider-block .info-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.provider-detail-page .claim-provider-link {
	text-decoration: underline;
}

.provider-detail-page .expand .wrpexp .clkexp.provider-info-label {
	color: #fff;
	background-color: #5d5d5d;
	background-position: top -74px right 17px;
	background-image: url("../images/svg/accordion.svg");
}
.provider-detail-page .provider-block-wrapper .expand {
  padding-bottom: 25px;
  padding-top: 30px;
}
.provider-detail-page .provider-info-wrapper .provider-block-wrapper:first-child .expand {
  padding-top: 15px;
}
.provider-detail-page .social-author li {
  width: 30px;
  padding: 0;
}
.provider-detail-page .hr-with-negative-margin-top-26px {
  margin-top: -26px;
}
.provider-detail-page .back a.claim-provider-link {
  font-size: 13px;
}
.provider-detail-page .expand .wrpexp .clkexp.provider-info-label {
  background-image: none;
}
.provider-detail-page .hr-mb-30 {
  margin-bottom: 30px;
}
.provider-detail-page .item-detail .mobile-image {
  width: 120px;
}
.form-group a#provider-map-link {
  position: static;
}

.expand.without-pt {
	padding-top: 1px !important;
}
.provider-detail-page .expand .wrpexp .clkexp {
  cursor: auto;
}
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}
.single-dropdown input[type=radio],
.location-dropdown input[type=radio],
.singleSelect-search input[type=radio],
.singleSelect-item input[type=radio],
.singleSelect-miles input[type=radio] {
  display: none;
}
.singleSelect-search {
    width: 152px;
}
.search-sort-by {
    float: right;
    margin-top: 12px;
}
.search-sort-by span {
    margin-right: 6px;
}
.results-found {
    padding-top: 14px;
    display: inline-block;
}

@media (max-width: 767px) {
  .provider-detail-page .expand .wrpexp .clkexp.provider-info-label {
    background-image: url("../images/svg/accordion.svg");
  }
  .provider-detail-page .social-provider.social-author-detail {
    margin: 0;
  }
  .provider-detail-page .item-detail .mobile-image {
    width: 90px;
  }
  .provider-detail-page .item-detail .mobile-social {
    padding: 20px 0 0 0;
  }
  .provider-detail-page img.mobile65 {
    width: 65px;
  }
  .provider-detail-page .post-filter.provider-info-wrapper {
    margin-top: 5px;
  }
  .provider-detail-page .provider-block-wrapper .expand {
    padding-bottom: 0;
		padding-top: 1px;
  }
  .provider-detail-page .provider-block {
    padding-top: 25px;
  }

  .provider-detail-page .expand .wrpexp .clkexp.expanded.provider-info-label {
		background-image: url("../images/directory/up-arrow-icon.png");
		background-position: top 15px right 15px;

	}

  /*.provider-detail-page .expand .wrpexp .clkexp.provider-info-label {*/
		/*padding-top: 6px;*/
	/*}*/

  .provider-detail-page .post-filter.provider-info-wrapper {
		font-size: 14px;
	}

  .provider-detail-page .general-info-wrapper {
		display: flex;
		flex-direction: column;
	}

  .provider-detail-page .general-info-wrapper .provider-map-wrapper {
		order: 1;
		padding: 0;
		margin-bottom: 20px;
	}

  .provider-detail-page .general-info-wrapper .provider-map-wrapper {
		order: 1;
		padding: 0;
		margin-bottom: 20px;
	}

  .provider-detail-page .general-info-wrapper .info-wrapper {
		order: 2;
		padding: 0;
	}
}
.inline-p p {
  display: inline;
}
.form-group a.normal {
  font-size: 16px;
  position: static;
}
.re-vote-btn .btn-form {
  margin-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
}
.sidebar .re-vote-btn .btn-form {
  display: block;
  margin: 0 auto 22px;
}

.form-group .form-control.search-text-widget {
	height: 40px;
}

.mb-30 {
	margin-bottom: 30px;
}

.required.left {
  text-align: left;
  margin-top: 0;
}
.tc-info-line {
  padding-bottom: 20px;
}
.dir-login-form {
  margin-bottom: 400px;
}
.dir-provider-form {
  margin-bottom: 80px;
}
label.single-custom-checkbox  {
  font-weight: normal;
  margin-bottom: 0;
}
label.single-custom-checkbox .customCheckbox label {
  top: 2px;
}
label.single-custom-checkbox span {
  font-size: 14px;
  color: #5d5d5d;
}

.form-control.with-error {
	border-color: #9c0f17;
}

#logged-bar {
  width:100%;
  height: 24px;
  text-transform: uppercase;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  line-height: 23px;
}
#logged-bar a {
  color: #fff;
  text-decoration: underline;
}
.is-logged .header .container {
  height: 78px;
}
.is-logged .header {
  border-width: 0;
  height: 102px;
}
body.is-logged {
  padding-top: 127px;
}
.is-logged .header.header-scroll {
  top: -104px;
}
.is-logged.change-header .header.header-scroll {
  top: 0;
  height: 58px;
}
.is-logged.change-header .header .container {
  height: 100%;
}

@media (min-width: 768px) and (max-width: 1349px) {
  .is-logged .social-header.menu-trigger {
    margin-top: 24px;
    height: 78px;
  }
  .is-logged.change-header .social-header.menu-trigger {
    height: 100%;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .tc-info-line {
    font-size: 16px;
  }
  .tc-info-line div a.pull-right {
    float: none !important;
  }
  .tc-info-line div:first-child {
    margin-bottom: 7px;
  }
  .is-logged .header {
    border-width: 0;
    height: 86px;
  }
  .is-logged .header .container {
    height: 46px;
  }
  .is-logged .social-header.menu-trigger {
    margin-top: 40px;
    height: 46px;
  }
  #logged-bar {
    height:40px;
    line-height: 19px;
    font-size: 12px;
  }
  .is-logged .navigation-container {
    padding-top: 86px;
  }
}
.form-group.label-only {
  margin-bottom: 7px;
}
.form-group.label-only .label-form {
  padding-bottom: 0;
  margin-bottom: 0;
}
.form-group.removable-inside {
  position: relative;
}
.form-group.removable-inside span.removable {
  background-image: url("../images/directory/x-icon.png");
  background-repeat: no-repeat;
  background-size: 70% 70%;
  cursor: pointer;
  display: block;
  height: 13px;
  position: absolute;
  right: 8px;
  top: 10px;
  width: 13px;
}
.multi-select-search {
  position: relative;
}
.multi-select-search ul.multi-select-search-selected {
  list-style: none;
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 25px;
}
.multi-select-search ul.multi-select-search-selected li {
  display: inline-block;
  margin-right: 15px;
}
.multi-select-search ul.multi-select-search-selected li span.multi-select-search-remove {
  background-image: url("../images/directory/x-icon.png");
  background-repeat: no-repeat;
  background-size: 70% 70%;
  background-position: center 3px;
  cursor: pointer;
  display: inline-block;
  height: 13px;
  width: 13px;
  position: static;
  margin-right: 4px;
}
.multi-select-search .ms-drop.bottom {
  top: 28px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}

#hours-add {
  position: relative;
  border: 1px solid #ededed;
  padding: 20px;
  margin-bottom: 15px;
}
#hours-add .cancel {
  background-image: url("../images/directory/x-icon.png");
  background-repeat: no-repeat;
  background-size: 70% 70%;
  cursor: pointer;
  display: block;
  height: 13px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 13px;
}
#hours-add .form-group label {
  font-weight: normal;
  color: #5d5d5d;
}
#hours-add .form-group .time-inline-input {
  display: inline-block;
  position: relative;
}
#hours-add .form-group .form-control {
  width: 105px;
  display: inline-block;
}
#hours-add .form-group .form-control.from-time {
  margin-right: 10px;
}
#hours-add .days {
  margin-top: 20px;
  margin-bottom: 8px;
  overflow: hidden;
}
#hours-add .days div {
  display: block;
  float: left;
  width: 14.2857%;
  border: 1px solid #ededed;
  margin: 0;
  border-left: none;
  text-align: center;
  color: #5d5d5d;
  cursor: pointer;
}
#hours-add .days div.active,
#hours-add .days div:hover {
  background-color: #ededed;
}
#hours-add .days div:first-child {
  border-left: 1px solid #ededed;
}
#hours-add .add-confirm {
  font-family: "tradegothicno2bold";
  font-size: 14px;
  color: #5d5d5d;
  position: static;
  float:right;
  cursor: pointer;
}
@media (max-width: 767px) {
  #hours-add .form-group .form-control {
    width: 60px;
  }
  .modal.in .modal-dialog {
    max-width: 90%;
  }
}
.contact-num {
  position: relative;
  margin-bottom: 20px;
}
.contact-num h4 {
  margin-top: 0;
}
.contact-num .remove-contact {
  position: absolute;
  top: 7px;
  right: 0;
  color: #adadad;
  font-family: "tradegothicno2bold";
}
.checkout-product img {
  width: 184px;
  float: left;
}
.checkout-product {
  margin-top: 30px;
  padding-bottom: 10px;
  overflow: hidden;
}
.checkout-product .product-order-info {
  float: left;
  width: calc(100% - 205px);
  margin-left: 20px;
}
.checkout-product .single-dropdown,
.checkout-product .location-dropdown {
  width: 140px !important;
}
.content .checkout-product strong {
  font-weight: bold;
}
.expand.tricky {
  color: #3f3f3f;
  font-family: "tradegothiclt";
  font-style: normal;
}
.expand.tricky .wrpexp .clkexp.info-label {
  background-color: #5d5d5d;
  background-position: right 17px top -74px;
  color: #fff;
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  letter-spacing: 0.5px;
  line-height: 18px;
  padding: 12px 0 12px 20px;
}
.expand.tricky .wrpexp .clkexp,
.expand.tricky .wrpexp .clkexp.expanded {
  background-image: none;
}
.mt30 {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .multi-select-search ul.multi-select-search-selected li {
    font-size: 14px;
  }
  .single-dropdown .ms-drop ul > li label span,
  .location-dropdown .ms-drop ul > li label span {
    font-size: 15px;
  }
  .checkout-product .product-title {
    margin-top: 20px;
  }
  .checkout-product img {
    float: none;
  }
  .checkout-product .product-order-info {
    float: none;
    width: 100%;
    margin-left: 0;
  }
  .checkout-product .single-dropdown,
  .checkout-product .location-dropdown {
    width: 100% !important;
  }
  .checkout-product {
    overflow: visible;
  }
}
.danger-p p {
  margin-bottom: 25px;
}
.danger-p p:last-child {
  margin-bottom: 0;
}
.danger-p p a {
  font-weight: bold;
  color: #9c0f17;
}
.checkout-confirmation .row {
  margin-bottom: 10px;
}
.wider-modal,
.wider-modal p,
.wide-modal,
.wide-modal p {
  font-size: 14.5px;
}
.wide-modal .modal-width {
  width: 640px !important;
}
.wide-modal .modal-customize {
  max-width: 640px;
}
.wider-modal .modal-width {
  width: 712px !important;
}
.wider-modal .modal-customize {
  max-width: 712px;
}
.wider-modal .modal-header,
.wide-modal .modal-header {
  padding: 15px 25px;
}
.wider-modal .list-group-item .radio label,
.wide-modal .list-group-item .radio label {
  font-family: "tradegothiclt";
  font-size: 14.5px;
}
.wider-modal #close-customize,
.wide-modal #close-customize {
  left: 97%;
}
.row.line-between .col-sm-6 {
  margin-top: 20px;
}
.row.line-between .col-sm-6 h3 {
  margin-top: 0px;
}
.row.line-between .col-sm-6:first-child {
  padding-right: 25px;
  border-right: 1px solid #ededed;
}
.row.line-between .col-sm-6:last-child {
  padding-left: 25px;
}
.two-columns .modal-header {
  padding-bottom: 0;
}
.two-columns .modal-body .row.line-between .col-sm-6 {
  margin-top: 0;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .two-columns .modal-dialog {
    margin-top: 55px;
  }
  .two-columns .modal-dialog .btn-primary {
    width: 115px;
    padding: 9px 0 11px;
    font-size: 13px;
    float:left;
  }
  .two-columns .modal-dialog .btn-primary:first-child {
    margin-right: 4px;
  }
  .row.line-between .col-sm-6:first-child {
    padding-right: 12px;
    border-right: none;
  }
}
.btn2-center-block {
  overflow: hidden;
  margin: 0 auto;
  display: flex;
}
.mb50 {
  margin-bottom: 50px;
}
.body-tabs {
  margin-top: 20px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .body-tabs {
    margin-left: -24px;
    margin-right: -24px;
  }
  .mobile-special-table .expand .wrpexp .clkexp:after {
    content: " ";
    background-image: url("../images/svg/accordion.svg");
    background-repeat: no-repeat;
    position: absolute;
    height: 70px;
    width: 50px;
    right: 0;
    top: 0;
    background-position: right 15px top -59px;
  }
  .mobile-special-table .expand .wrpexp .clkexp {
    background-color: #5d5d5d;
    font-size: 15px;
    height:70px;
    padding: 13px 30px 15px 15px;
    color: #fff;
    background-image: none;
  }
  .mobile-special-table .expand .wrpexp .clkexp.expanded:after {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .mobile-special-table .expand .wrpexp .cntexp .clkexp {
    background-color: #efefef;
    color: #5d5d5d;
    background-image: none;
  }
  .mobile-special-table .expand .wrpexp .cntexp .clkexp:after {
    background-image: none;
  }
  .mobile-special-table .expand .wrpexp .cntexp .clkexp.one-line {
    display: block;
    padding-top: 20px;
  }
  .mobile-special-table .expand .wrpexp .clkexp {
    overflow: visible;
    white-space: normal;
    text-overflow: clip;
  }
}
.table.table-dynamic {
  margin-top: 00px;
}
.table.table-dynamic thead tr th {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "tradegothiclt";
  font-weight: bold;
  font-size: 15px;
}
.table.table-dynamic tbody tr td {
  font-size: 16px;
}
.table.table-dynamic tbody tr td a {
  font-weight: bold;
}
.back-margin-trick {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}
.cancel-sub-link {
  color: #828282;
  font-size: 13px;
  text-decoration: underline;
}

.expand.tricky.can-hide .wrpexp .clkexp:after {
  content: " ";
  background-image: url("../images/svg/accordion.svg");
  background-repeat: no-repeat;
  position: absolute;
  height: 70px;
  width: 50px;
  right: 0;
  top: 0;
  background-position: right 15px top -73px;
}
.expand.tricky.can-hide .wrpexp .clkexp {
  background-color: #5d5d5d;
  font-size: 15px;
  /*height:70px;*/
  padding: 13px 30px 15px 15px;
  color: #fff;
  /*background-image: none;*/
}
.expand.tricky.can-hide .wrpexp .clkexp.expanded:after {
  background-position: right 17px top -45px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dir-provider-form-edit .expand.tricky {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
}
.dir-provider-form-edit .expand .wrpexp .cntexp {
  padding: 20px 0;
}
.dir-provider-form-edit .expand .wrpexp .cntexp p {
  font-size: 1.08rem;
}
.back-margin-trick.mb20 {
  margin-bottom: 20px !important;
}
.modal-dialog .btn2-center-block .btn-primary {
  padding: 9px 0 11px;
  width: 115px;
}
.img-form .removable {
    background-image: url("../images/directory/x-icon.png");
    background-repeat: no-repeat;
    background-size: 70% 70%;
    cursor: pointer;
    display: block;
    height: 13px;
    position: absolute;
    left: 110px;
    top: 0;
    width: 13px;
}
.remove-contact {
    cursor: pointer;
}

#provides_modal .modal-width {
	max-width: 640px;
	width: 100% !important;
}

#provides_modal .modal-customize {
	width: 100%;
	max-width: 640px;
}

#provides_modal .radio label {
	padding-left: 0;
}

#provides_modal .list-group-item {
	border: none;
	padding: 0;
	padding-bottom: 5px;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s ease;
}
.fade-enter, .fade-leave-active {
    opacity: 0;
}
.transition-spa-load {
    opacity: 0;
    transition: opacity .3s ease;
}
.transition-spa-load.ready {
    opacity: 1;
}
.table.providers-list thead tr th:first-child {
    width: 50%;
}
.table.providers-list thead tr th:nth-child(4) {
    width: 20%;
}
.table.orders-list thead tr th:nth-child(1) {
    width: 22%;
}
.table.orders-list thead tr th:nth-child(4) {
    width: 15%;
}
.table.orders-list thead tr th:nth-child(5) {
    width: 12%;
}
.table.orders-list thead tr th:nth-child(6) {
    width: 18%;
}

.tinymce-custom-style .mce-splitbtn .mce-open {
    display: none;
}
.tinymce-custom-style .mce-statusbar.mce-stack-layout-item {
    display: none;
}
.tinymce-custom-style div.mce-edit-area {
    background-color: #f9f8f8 !important;
    border: 1px solid #ccc !important;
    border-color: #ededed !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075) !important;
}
.tinymce-custom-style div.mce-edit-area iframe {
    background-color: #f9f8f8 !important;
    border-color: #ededed !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075) !important;
}
.tinymce-custom-style .mce-toolbar-grp.mce-stack-layout-item {
    background-color: #5d5d5d !important;
    padding-top: 0 !important;
}
.tinymce-custom-style .mce-ico {
    color: #fff !important;
}
.tinymce-custom-style .mce-btn {
    background-color: #5d5d5d !important;
}
.tinymce-custom-style .mce-panel {
    border: none !important;
}

.mt-0 {
	margin-top: 0;
}

.mt-n-25 {
	margin-top: -25px;
}

.loader-slide {
    display: block;
    margin: 30px auto 0 auto;
    font-size: 10px;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.0);
    border-right: 1px solid rgba(255, 255, 255, 0.0);
    border-bottom: 1px solid rgba(255, 255, 255, 0.0);
    border-left: 1px solid #000;

    -webkit-animation: rotating 0.5s linear infinite;
    -moz-animation: rotating 0.5s linear infinite;
    -ms-animation: rotating 0.5s linear infinite;
    animation: rotating 0.5s linear infinite;
}

.loader-slide,
.loader-slide:after {
    border-radius: 50%;
    width: 25px;
    height: 25px;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.clear-both {
    clear:both;
}
.tabs ul.nav-tabs li a,
.tabs ul.nav-tabs li {
    cursor: pointer !important;
}
.tall-image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-right: 20px;
    border-bottom: 1px solid #f48a31;
    transition: none;
}
.tall-image:hover {
    border-bottom: 2px solid #f48a31;
    height: 101px;
    margin-bottom: -1px;
}
.margin-top-xs {
    margin-top: 0;
}
@media (max-width: 767px) {
    .margin-top-xs {
        margin-top: 20px;
    }
}
@media (max-width: 480px) {
    .content-block .media a.pull-left.tall-image img.media-object {
        width: 75px;
        height: auto;
    }
    .tall-image {
        width:75px;
        height: 75px;
    }
}
#video-container iframe {
    display: block;
}
/*.table.table-drugs tbody tr td small {*/
    /*overflow-wrap: break-word;*/
    /*word-wrap: break-word;*/
    /*-ms-word-break: break-all;*/
    /*!* This is the dangerous one in WebKit, as it breaks things wherever *!*/
    /*word-break: break-all;*/
    /*!* Instead use this non-standard one: *!*/
    /*word-break: break-word;*/
/*}*/
#rssWidgetSnippet .collapse-group {
    right: 5px;
}

@media (max-width: 767px) {
    .slide-show-slide-item .img-full-with-bg {
        width: 100%;
    }
    .slide-show-slide-item.img-full.img-featured {
        margin-top: -20px;
    }
    .slide-show-slide-item .full-img.main-featured-img.full-img-without-scale {
        max-height: 398px !important;
    }
}

.post-content blockquote {
    font-family: 'tradegothiclt';
    color: #363636;
    font-size: 16px;
    line-height: 36px;
    padding: 0 0;
    text-align: left;
    max-width: 100%;
    margin: 0 0 0 30px;
    border: none;
}
.post-content blockquote p {
    display: block;
    font-size: 16px;
    line-height: 36px;
}
.post-content blockquote:after,
.post-content blockquote:before {
    width: 0;
    height: 0;
}

@media only screen and (max-width: 767px) {
    .post-content blockquote p {
        font-size: 15px;
    }
}

.post-content blockquote.pullquote {
    font-family: 'FarnhamDisplay-LightItal';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
}

.post-content blockquote.pullquote {
    color: #000000;
    font-size: 28px;
    line-height: 48px;
    padding: 0 0;
    text-align: center;
    max-width: 500px;
    margin: 25px auto 30px auto;
    border: none; }
.post-content blockquote.pullquote:before {
    width: 43px;
    height: 30px;
    background: transparent url("../images/quote_before.png") center no-repeat;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
    content: "";
    margin: 0px auto 15px auto;
    vertical-align: -0.4em;
    clear: both;
    display: block; }
.post-content blockquote.pullquote:after {
    width: 43px;
    height: 30px;
    background: transparent url("../images/quote_after.png") center no-repeat;
    -moz-background-size: contain;
    -o-background-size: contain;
    -webkit-background-size: contain;
    background-size: contain;
    content: "";
    margin: 15px auto 0px auto;
    vertical-align: -0.4em;
    clear: both;
    display: block; }
.post-content blockquote.pullquote p {
    display: inline;
    font-size: 28px;
    line-height: 48px;
    margin: 0 0; }

@media only screen and (max-width: 767px) {
    .post-content blockquote.pullquote {
        font-size: 18px;
        line-height: 33px;
        margin: 15px auto 20px auto;
    }

    .post-content blockquote.pullquote p {
        font-size: 18px;
        line-height: 31px;
        padding: 0 0;
    }

    blockquote.pullquote:before {
        width: 30px;
        height: 20px;
        margin: 0px auto 5px auto;
    }

    blockquote.pullquote:after {
        width: 30px;
        height: 20px;
        margin: 1px auto 0px auto;
    }
}

.table.table-drugs tbody tr td {
    word-wrap: break-word;
}
.embed-poll.poll-id {
    background-color: #f9f8f8;
    padding: 15px 40px;
    border-top: 4px solid #f48a31;
}
.embed-poll.poll-id .article-title {
    font-size: 24px;
    line-height: 32px;
}
.content-block.extended .post-content .embed-poll.poll-id ul,
.post-content .embed-poll.poll-id ul {
    padding-left: 0 !important;
}
.embed-poll.poll-id .list-group-item {
    background-color: #f9f8f8;
}
.embed-poll.poll-id .iradio.checked,
.embed-poll.poll-id .iradio {
    margin-top: 6px;
}
@media only screen and (max-width: 767px) {
    .embed-poll.poll-id {
        background-color: #f9f8f8;
        padding: 8px 20px 30px;
        border-top: 4px solid #f48a31;
    }
}

div.post-content .static-page-form label {
    line-height: 21px;
}
.lh24 {
    line-height: 24px;
}
.static-page-form {
    margin-top: -15px;
}

@media only screen and (max-width: 767px) {
    .static-page-form .btn-primary {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
    }
}

.box .box-content .item.video-thumbnail {
    margin: 0 -17px 5px;
}
.box .box-content p.video-title-p {
    margin: 15px 0 0 0;
}

p.drop-cap:first-letter {
    float: left;
    font-size: 380%;
    font-weight: bold;
    line-height: 108%;
    padding-top: 4px;
    padding-right: 6px;
    padding-left: 3px;
}

@-moz-document url-prefix() {
    p.drop-cap:first-letter { padding-top: 17px; }
}
@supports (-moz-appearance:none) {
    p.drop-cap:first-letter { padding-top: 17px; }
}

@media only screen and (max-width: 767px) {
    p.drop-cap:first-letter {
        font-size: 310%;
        font-weight: bold;
        line-height: 70%;
        padding-top: 4px;
        padding-right: 4px;
        padding-left: 3px;
    }
}
.directory-search.custom-search {
    margin-right: 0;
}
.directory-search.custom-search .form-control {
    width: calc(100% - 12px);
}
.directory-search.custom-search .btn-primary {
    margin: 0;
    float: right;
    height: 44px;
    line-height: 1.46;
}

.directory-search.custom-search {
    margin-right: 0;
}
.directory-search.custom-search .form-control {
    width: calc(100% - 12px);
}
.directory-search.custom-search .btn-primary {
    margin: 0;
    float: right;
    height: 44px;
    line-height: 1.46;
}

.content-block > ul.pagination {
    padding-left: 0;
}
.content-block > ul.pagination > li:first-child,
.content-block > ul.pagination > li:last-child {
    border-radius: 0;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.pagination>li>a, .pagination>li>span {
    margin-left: 3px;
    padding-left: 0;
    padding-right: 0;
    width: 33px;
    text-align: center;

    color: #363636;
    border: none;
    padding-top: 9px;
    width: auto;
    margin: 0 5px;
}
.pagination>li>a.btn-prv {
    margin-right: 30px;
}
.pagination>li>a.btn-nxt {
    margin-left: 30px;
    margin-right: 0;
}
.pagination>li>span.pag-sep:hover,
.pagination>li>span.pag-sep {
    background: none;
    border: none;
    color: #363636;
    padding-top: 8px;
    margin: 0 0;
}
.pagination>li>a.btn {
    text-transform: uppercase;
    border: none;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    height: 40px;

    -moz-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px 0px 0px 0px;
    background-color: #f48a31;
    font-size: 12px;
    padding: 9px 30px 11px 30px;
    -webkit-padding-before: 12px;
    -moz-box-shadow: 0.5px 0.9px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
    -webkit-box-shadow: 0.5px 0.9px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);
    box-shadow: 0.5px 0.9px 5px rgba(1, 1, 1, 0.04), 0 0 8px rgba(0, 0, 0, 0.02);

    background-color: #f48a31;
    color: #ffffff;
    font-size: 14px;
    font-family: "tradegothicno2bold";
    font-style: normal;

    color: #fff;
    width: auto;
}
.pagination>li>a.btn:hover {
    color: #fff;
    background: #363636;
}
@media only screen and (max-width: 1360px) {
    .pagination>li>a.btn {
        padding: 12px 15px 11px 15px;
    }
    .pagination>li>a.btn-prv {
        margin-right: 15px;
    }
    .pagination>li>a.btn-nxt {
        margin-left: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .pagination>li>a, .pagination>li>span {
        display: none;
    }
    .pagination>li>a.btn {
        display: block;
        width: 120px;
    }
    .pagination>li>a.btn-prv {
        margin-right: 20px;
    }
    .pagination>li>a.btn-nxt {
        margin-left: 0px;
    }
}

span.search-found {
    background-color: yellow;
}
@media only screen and (max-width: 767px) {
    .directory-search.custom-search .form-control {
        width: 100%;
    }
}
.content-block .media a.pull-left.small-image-support {
    position: relative;
    height: 100px;
    width: 120px;
}
.content-block .media a.pull-left.small-image-support img.media-object {
    top: 50%;
    transform: translate(0, -50%);
    border-bottom: none;
    position: absolute;
}
.content-block .media a.pull-left.small-image-support .border-emulator {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 1px;
}
.content-block .media:hover a.pull-left.small-image-support .border-emulator {
    height: 2px;
}
@media only screen and (max-width: 767px) {
    .directory-search.custom-search .form-control {
        width: 100%;
    }
    .content-block .media a.pull-left.small-image-support {
         height: 75px;
         width: 94px;
    }
    .content-block .media a.pull-left.small-image-support .border-emulator {
        width: 75px;
    }
    .content-block .media a.pull-left.small-image-support img.media-object {
        max-width: 75px;
    }
}

.social-author li a .fa-instagram {
    color: #8a3ab9;

}
.social-author li a .fa-instagram:hover {
    color: #6900b9;
}
.social-author li {
    padding-left: 7px;
    padding-right: 7px;
}
.visible-form {
    position: relative;
    transition: 0.5s;
    opacity: 1;
}
.invisible-form {
    opacity: 0;
}
.modal-spinner {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -60px;
}
#newsletter-page.post-content ul li,
#newsletter-page .form-group label {
    font-size: 16px;
    line-height: 24px;
}
div#newsletter-page.post-content .form-group {
    line-height: 24px;
}
#newsletter-page.post-content ul.list-unstyled {
    padding-left: 0;
}

.pullquote .qmark {
    padding: 25px 10px 0;
    background: #ededed;
}
.pullquote .qmark + .link {
    padding: 0 10px 25px;
    background: #ededed;
}

.table-drugs.visible-xs thead {
    background: #e7e7e7;
    color: #000;
}

/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container, .flip-container .front, .flip-container .back {
    /*width: 360px;*/
    max-width: 100%;
    min-height: 100px;
    height: auto;
    cursor: default;

	display: inline-block;
	min-width: 200px;
}

/* flip speed goes here */
.flip-container .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
}
.flip-container .flipper:after {
	content: "Click to Flip";
	display: block;
	position: absolute;
	width: 100%;
	height: 30px;
	text-align: center;
	color: #fff;
	background: #000;
	opacity: 0.5;
	bottom: 0;
	transition: 0.2s linear;
	line-height: 30px;
	font-weight: 600;
	z-index: 2;
}
.flip-container:hover .flipper:after,
.flip-container.hover .flipper:after {
	opacity: 0;
}
.post-content .flip-container img {
	margin: 0 auto;
	display: block;
}
.flip-container .front span,
.flip-container .back span,
.flip-container .front p,
.flip-container .back p {
	padding: 5px 10px;
	display: block;
}
.flip-container .front .caption span,
.flip-container .back .caption span,
.flip-container .front .caption p,
.flip-container .back .caption p {
	padding: 0;
}

/* hide back of pane during swap */
.flip-container .front, .flip-container .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ededed;
	transition: 0.25s linear;
    /*padding: 0 10px;*/
}

/* front pane, placed above back */
.flip-container .front {
    position: static;
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}
.flip-container.hover .front {
	opacity: 0;
}

/* back, initially hidden pane */
.flip-container .back {
    transform: rotateY(180deg);
	opacity: 0;
}
.flip-container.hover .back {
	opacity: 1;
}
.flip-container .image-wrap.image-no-float,
.flip-container .image-wrap {
    padding-bottom: 0;
    display: block;
    /*margin-left: -10px;*/
    /*width: calc(100% + 20px);*/
}
.ad-below-title {
	margin-top: 30px;
	padding-bottom: 0 !important;
}
@media (max-width: 767px) {
	.ad-below-title {
		margin-top: 30px !important;
	}
}
/*.ad-billboard-fixed .ad-billboard {*/
	/*position: fixed;*/
	/*min-height: 120px;*/
	/*left: 0;*/
	/*margin-left: calc(50% + 85px);*/
	/*-webkit-transform: translateX(-50%);*/
	/*transform: translateX(-50%);*/
	/*width: 1120px;*/
	/*background: #f2f2f2;*/
	/*top: 78px;*/
	/*padding-top: 40px;*/
	/*z-index: 1020 !important;*/
/*}*/
/*.ad-billboard-fixed .row-content {*/
	/*margin-top: 120px;*/
/*}*/
/*.ad-billboard-fixed .ad-block div {*/
	/*width: auto !important;*/
/*}*/
/*.ad-billboard-fixed .ad-block.ad-billboard span {*/
	/*top: 20px;*/
/*}*/
.ad-top-fixed .ad-top-first {
	position: fixed;
	min-height: 120px;
	top: 105px;
	padding-top: 25px;
	padding-bottom: 10px;
	z-index: 90 !important;
	background: #FFFFFF;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	left: calc(50% - 82px);
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
.is-logged.ad-top-fixed .ad-top-first {
	top: 127px;
}
@media only screen and (min-width: 1350px) {
	.ad-top-fixed.dropdown-active .ad-top-first {
		left: calc(50% - (82px - 181px));
	}
}
@media only screen and (max-width: 1349px) and (min-width: 1024px) {
	.ad-top-fixed .ad-top-first {
		left: calc(50% - 158px);
		-webkit-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.ad-top-fixed.menu-active .ad-top-first {
		left: 220px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	.ad-top-fixed.menu-active.dropdown-active .ad-top-first {
		left: 396px;
	}
}
@media only screen and (max-width: 1023px) {
	.ad-top-fixed .ad-top-first {
		left: calc(50% + 24px);
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
		-webkit-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.ad-top-fixed.menu-active .ad-top-first {
		left: 220px;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	.ad-top-fixed.menu-active.dropdown-active .ad-top-first {
		left: 396px;
	}
}
@media only screen and (max-width: 767px) {
	.ad-top-fixed .ad-top-first {
		left: calc(50% + 20px);
	}
	.ad-top-fixed.menu-active.dropdown-active .ad-top-first,
	.ad-top-fixed.menu-active .ad-top-first {
		left: calc(50% + 20px);
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
}
.navigation-container .side-menu {
	z-index: 95;
}

.is-logged.change-header.ad-top-fixed .ad-top-first,
.change-header.ad-top-fixed .ad-top-first,
.is-logged.change-header.ad-top-fixed .ad-top-first.ad-top-trick-scroll,
.change-header.ad-top-fixed .ad-top-first.ad-top-trick-scroll {
	top: 58px;
}
.ad-top-fixed .ad-top-first.ad-top-trick-scroll {
	top: 78px;
	padding-top: 52px;
}
.ad-top-fixed .ad-top-first.ad-top-trick-scroll.ad-block span.ad-label {
	top: 32px;
}

.ad-top-fixed .ad-top-first.ad-block span.ad-label {
	top: 5px;
}
.ad-top-fixed .page-wrapper .col-md-8.column.content {
	padding-top: 100px;
}
@media only screen and (max-width: 1349px) and (min-width: 1024px) {
	.ad-top-fixed .ad-top-first {
		width: 660px;
	}
}
@media only screen and (max-width: 1023px) and (min-width: 791px) {
	.ad-top-fixed .ad-top-first {
		width: 100%;
		margin-left: -24px;
	}
}
@media only screen and (max-width: 790px) and (min-width: 768px) {
	.ad-top-fixed .ad-top-first {
		margin-left: -20px;
		width: calc(100vw);
	}
}
@media only screen and (max-width: 1024px) {
	.ad-top-fixed.menu-active .ad-top-first {
		z-index: 1 !important;
	}
}
.advanced-search-modal .btn-primary {
	height: 44px;
	line-height: 1.46;
}
@media only screen and (max-width: 767px) {
	.ad-top-fixed .ad-top-first {
		width: calc(100vw);
		top: 52px;
	}
	.is-logged.ad-top-fixed .ad-top-first {
		top: 92px;
	}
	.ad-top-fixed .page-wrapper .col-md-8.column.content {
		padding-top: 260px;
	}
	.ad-top-fixed .page-wrapper .col-md-8.column.content.lesson-detail {
		padding-top: 270px;
	}
	.change-header.ad-top-fixed .ad-top-first,
	.change-header.ad-top-fixed .ad-top-first.ad-top-trick-scroll,
	.ad-top-fixed .ad-top-first.ad-top-trick-scroll {
		top: 25px;
	}
	.is-logged.change-header.ad-top-fixed .ad-top-first,
	.is-logged.change-header.ad-top-fixed .ad-top-first.ad-top-trick-scroll,
	.is-logged.ad-top-fixed .ad-top-first.ad-top-trick-scroll {
		top: 65px;
	}
	.is-logged.change-header .header .container {
		height: inherit;
	}
	.ad-top-fixed.ad-top-mobile-50 .page-wrapper .col-md-8.column.content {
		padding-top: 60px;
	}
	.ad-top-fixed.ad-top-mobile-50 .page-wrapper .col-md-8.column.content.lesson-detail {
		padding-top: 70px;
	}
	.ad-top-fixed.ad-top-mobile-90 .page-wrapper .col-md-8.column.content {
		padding-top: 100px;
	}
	.ad-top-fixed.ad-top-mobile-90 .page-wrapper .col-md-8.column.content.lesson-detail {
		padding-top: 110px;
	}
	.ad-top-fixed.ad-top-mobile-50 .ad-top-first {
		min-height: 50px;
	}
	.search-wrapper.search-submit-form {
		height: 88px;
	}
}
.custom-search .form-group.zip-search {
	margin-bottom: 0;
}
#advanced-search-modal .poll .list-group-item .radio label {
	font-family: "tradegothiclt";
}
#advanced-search-modal .iradio {
	margin-top: 6px;
	margin-right: 8px;
}
#advanced-search-modal .poll {
	margin-bottom: 0;
}
#advanced-search-modal .poll .list-group-item .radio {
	margin-top: 5px;
}
.daterangepicker {
	z-index: 9999;
}
.daterangepicker .drp-buttons .btn {
	padding: 4px 17px;
}

.img-preload-container {
	width: 100%;
	background-color: #e8e8e8;
	position: relative;
	transition: 0.2s ease;
	display: block;
}
.img-preload-container img {
	position: absolute;
	opacity: 0;
	transition: 0.2s ease;
}
.img-preload-container::after {
	content: " ";
	position: absolute;
	display: block;
	top: 50%;
	left: 50%;
	margin: -13px 0 0 -13px;
	font-size: 10px;
	border-top: 1px solid rgba(255, 255, 255, 0.0);
	border-right: 1px solid rgba(255, 255, 255, 0.0);
	border-bottom: 1px solid rgba(255, 255, 255, 0.0);
	border-left: 1px solid #000;
	border-radius: 50%;
	width: 26px;
	height: 26px;

	-webkit-animation: rotating 0.5s linear infinite;
	-moz-animation: rotating 0.5s linear infinite;
	-ms-animation: rotating 0.5s linear infinite;
	animation: rotating 0.5s linear infinite;
}
.img-preload-container.loaded {
	/*background-color: transparent;*/
}
.img-preload-container.loaded::after {
	/*display: none;*/
}
.spinner.always-visible {
	display: block;
}

.img-preload-container.img-100_75 {
	width: 100px;
	margin-right: 10px;
	padding-top: 100px;
}
.img-preload-container.img-150_75 {
	width: 150px;
	padding-top: 150px;
}
.img-preload-container.img-115 {
	width: 115px;
	padding-top: 115px;
	margin: 0 auto;
}
.img-preload-container.img-708 {
	width: 100%;
	padding-top: 47.74%;
}
.img-preload-container.img-76_42 {
	width: 76px;
	padding-top: 42px;
}
.img-preload-container.img-38_21 {
	width: 38px;
	padding-top: 21px;
}
@media only screen and (max-width: 480px) {
	.img-preload-container.img-100_75 {
		width: 75px;
		margin-right: 10px;
		padding-top: 75px;
	}
	.img-preload-container.img-150_75 {
		width: 75px;
		margin-right: 10px;
		padding-top: 75px;
	}
}

/*.slick-initialized .slick-slide.paid-item {*/
	/*background-color: #e8e8e8;*/
/*}*/
.slick-slide.paid-item .slide-title {
	padding: 0 10px;
}
.slick-slide.paid-item:hover .h6 {
	color: #363636;
}
/*.fb_iframe_widget.fb-like span,*/
/*.fb_iframe_widget.fb-like iframe,*/
/*.fb_iframe_widget.fb-like {*/
	/*width: 150px !important;*/
/*}*/
.fb_iframe_widget.fb-like {
	/*margin-left: -77px;*/
}
.fb_iframe_widget.fb-like > span,
.fb_iframe_widget.fb-like > span > iframe {
	width: 85px !important;
}
/*.twitter-share-button {*/
	/*margin-left: -88px;*/
/*}*/

.radio-item {
	display: inline-block;
	position: relative;
	padding: 0 6px;
	margin: 10px 0 0;
}

.radio-item input[type='radio'] {
	display: none;
}

.radio-item label:before {
	content: " ";
	display: inline-block;
	position: relative;
	top: 5px;
	margin: 0 9px 0 0;
	width: 20px;
	height: 20px;
	border-radius: 11px;
	border: 1px solid #999;
	background-color: #f9f9f9;
}

.radio-item input[type=radio]:checked + label:after {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 10px;
	left: 11px;
	content: " ";
	display: block;
}